import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Accordion,
  Block2,
  Box,
  Boxes,
  Button,
  Container,
  Image,
  Link,
  LinkProps,
  Page,
  Row2,
  Text,
  Title,
  Zip,
} from 'src/components';
import paths from 'src/constants/paths';
import urls from 'src/constants/urls';
import { ReactComponent as AetnaCvsHealthLogoSvg } from './images/aetna-cvs-health-logo.svg';
import goalsImage from './images/benefits/goals@2x.png';
import insuranceImage from './images/benefits/insurance@2x.png';
import taxesImage from './images/benefits/taxes@2x.png';
import { ReactComponent as AetnaHandSvg } from './images/value-props/aetna-hand.svg';
import { ReactComponent as CalculatorSvg } from './images/value-props/calculator.svg';
import { ReactComponent as DoctorSvg } from './images/value-props/doctor.svg';
import { ReactComponent as TabletSvg } from './images/value-props/tablet.svg';
import { TextProps } from 'src/components/Text';
import { navigate } from 'gatsby';

const FAQS = [
  {
    question: 'TODO lorem ipsum odor amet, consectetuer adipiscing elit?',
    answer:
      'TODO lorem ipsum odor amet, consectetuer adipiscing elit. Ultrices varius malesuada purus varius risus nam natoque nisl. Viverra aenean habitasse odio; class libero ex montes habitasse.',
  },
];

const GUIDE_LINKS = [
  {
    text: 'TODO lorem ipsum odor amet',
    to: '#TODO',
  },
];

function AetnaSignUpText({ color = 'purple' }: { color?: LinkProps['color'] }) {
  return (
    <Text element="p" flush>
      Already have an Aetna CVS Health plan?{' '}
      <Link to={urls.SIGN_UP} color={color}>
        <Text weight={color === 'inherit' ? 'bold' : undefined}>Start saving with Catch.</Text>
      </Link>
    </Text>
  );
}

const Hero = styled.header`
  padding: 180px var(--side-margin) 116px;
`;

const HeroInner = styled.header`
  display: flex;
  align-items: center;
  gap: 72px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const SAetnaCvsHealthLogoSvg = styled(AetnaCvsHealthLogoSvg)`
  max-width: 100%;
  height: auto;
`;

const HeroImage = styled(Text)`
  width: 100%;
`;

const ValuePropsGrid = styled.div`
  --columns: 4;
  display: grid;
  gap: 48px 56px;
  grid-template-columns: repeat(var(--columns), 1fr);
  margin: 0 0 72px;

  @media (max-width: 980px) {
    --columns: 2;
  }

  @media (max-width: 499px) {
    --columns: 1;
  }
`;

const BenefitsGrid = styled.div`
  --columns: 3;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(var(--columns), 1fr);

  @media (max-width: 980px) {
    --columns: 1;
    max-width: 384px;
    margin: auto;
  }
`;

const Benefit = styled(Text)`
  background: var(--c-bg-2);
  border-radius: 32px;
  padding: 32px;
`;

const BenefitImage = styled(Image)`
  display: block;
  margin: auto;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%) saturate(0) brightness(500%);
  }

  ${({ $shadow }) =>
    $shadow &&
    css`
      filter: drop-shadow(0 2.89px 23.15px #25272e1a);
    `}
`;

const Savings = styled.section`
  display: flex;
  align-items: center;
  gap: 96px;

  @media (max-width: 1080px) {
    flex-direction: column;
    gap: 64px;
  }
`;

const SavingsBody = styled.div`
  max-width: 528px;
`;

const Guide = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 48px;
  }
`;

const GuideShield = styled.div`
  mix-blend-mode: luminosity;

  @media (max-width: 980px) {
    max-width: 240px;
  }
`;

const FaqFooter = styled(Text)`
  color: #ffffffcc;
`;

const isTexas = false;

export default function Aetna() {
  const valueProps = [
    {
      title: 'Quality care',
      icon: DoctorSvg,
      text: 'The coverage you need, plus access to the Aetna® network of local doctors, specialists and hospitals you know and trust.',
    },
    {
      title: 'Affordable care',
      icon: TabletSvg,
      text: isTexas
        ? 'Plans starting at $0, $0 preventive care, $0 in-network walk-in clinic visits, $3 copay for over 200 generic drugs and select plans with $0 PCP visits.*'
        : 'Plans starting at $0, $0 preventive care, $0 MinuteClinic® visits, $3 copay for over 200 generic drugs and select plans with $0 PCP visits.*',
    },
    {
      title: 'Extra value',
      icon: AetnaHandSvg,
      text: 'A $25 allowance every 3 months to shop over-the-counter CVS Health® brand wellness products. Plus, adult dental and vision with select plans.*',
    },
    {
      title: 'Financial tools',
      icon: CalculatorSvg,
      text: 'Save for taxes, time off,  emergencies and more with Catch, included when you enroll in an Aetna CVS Health plan.',
    },
  ];

  const benefits = [
    {
      title: 'Health coverage',
      value: 'Active',
      image: insuranceImage,
      imageShadow: true,
    },
    {
      title: 'Taxes',
      value: '$3,800',
      image: taxesImage,
    },
    {
      title: 'Goals',
      value: '$6,500',
      image: goalsImage,
    },
  ];

  const title = 'Aetna CVS Health® plan';
  const description =
    'Get more from your Aetna CVS Health® plan, like exclusive access to financial tools built for freelancers from our partner, Catch.';

  const footerTextProps: Partial<TextProps> = {
    element: 'p',
    size: 'xsmall',
  };

  //@todo temporary, remove once we are ready to show Aetna
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      navigate('/');
    }
  }, []);
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <Page
      hideBanner
      hideLinks
      metadata={{
        description,
        title,
      }}
      navBorder={false}
      title={title}
    >
      {/* Apply Aetna fonts */}
      <Helmet>
        <body className="aetna-page" />
      </Helmet>
      <Hero>
        <Container>
          <HeroInner>
            <div>
              <Row2>
                <Row2 size="large">
                  <SAetnaCvsHealthLogoSvg aria-label="Aetna CVS Health®" />
                </Row2>
                <Row2 size="small">
                  <Text element="h2" size="xxlarge">
                    Individual & Family plans
                  </Text>
                </Row2>
                <Row2 size="xsmall">
                  <Text element="h1" size="xxxxxlarge">
                    Health insurance designed for you
                  </Text>
                </Row2>
                <Text element="p" flush size="large">
                  {description}
                </Text>
              </Row2>
              <Row2>
                <Zip cta="Find plans" />
              </Row2>
              <AetnaSignUpText />
            </div>
            <HeroImage align="center">
              <StaticImage
                src="./images/heart@2x.png"
                alt=""
                loading="eager"
                placeholder="none"
                width={520}
              />
            </HeroImage>
          </HeroInner>
        </Container>
      </Hero>
      <Block2 color="grey">
        <Container>
          <Title subtitle="Freelance fearlessly with quality coverage from Aetna CVS Health and financial tools from Catch. Whether you’re a photographer, a founder or still figuring it out, we’ve got your back.">
            Get more from your health plan
          </Title>
          <ValuePropsGrid>
            {valueProps.map((item) => (
              <Text align="left" element="figure" key={item.title}>
                <Row2>
                  <item.icon />
                </Row2>
                <figcaption>
                  <Row2 size="xxxsmall">
                    <Text element="h3" size="xlarge">
                      {item.title}
                    </Text>
                  </Row2>
                  <Text color="grey" element="p" flush>
                    {item.text}
                  </Text>
                </figcaption>
              </Text>
            ))}
          </ValuePropsGrid>
          <Row2 size="xsmall">
            <Button to={urls.SIGN_UP}>Get started</Button>
          </Row2>
          <AetnaSignUpText />
        </Container>
      </Block2>
      <Block2>
        <Row2 size="xxlarge">
          <Container>
            <Row2 size="xxlarge">
              <Text align="center" element="section">
                <Title
                  pretitle="Benefits beyond insurance"
                  subtitle="Put aside a percentage of each paycheck for taxes, time off and more. Aetna CVS Health members get exclusive access to tools from Catch that streamline savings and help you stay on top of your money."
                >
                  Simplify your finances with Catch
                </Title>
                <Row2 size="large">
                  <BenefitsGrid>
                    {benefits.map((item) => (
                      <Benefit align="left" element="figure" key={item.title}>
                        <Row2 element="figcaption" size="small">
                          <Row2 size="xxxsmall">
                            <Text color="purple" element="h3">
                              {item.title}
                            </Text>
                          </Row2>
                          <Text size="xxlarge">{item.value}</Text>
                        </Row2>
                        <BenefitImage src2x={item.image} $shadow={item.imageShadow} />
                      </Benefit>
                    ))}
                  </BenefitsGrid>
                </Row2>
                <Row2 size="small">
                  <Button to={urls.SIGN_UP}>Get started</Button>
                </Row2>
                <AetnaSignUpText />
              </Text>
            </Row2>
            <Savings>
              <StaticImage
                src="./images/photographer@2x.png"
                alt="Kevin’s premium $17/mo"
                placeholder="blurred"
                width={539}
              />
              <SavingsBody>
                <Title>Save on your monthly premium</Title>
                <Row2 size="large">
                  <Text color="grey" element="div">
                    <Text element="p" size="large">
                      Catch helps you get the biggest discounts by finding the tax credits you
                      qualify for. Savings are automatically applied when you shop.
                    </Text>
                    <Text element="p" flush size="large">
                      Plus,{' '}
                      <Link to={paths.CONTACT} color="purple">
                        contact Catch
                      </Link>{' '}
                      for free expert support along the way.
                    </Text>
                  </Text>
                </Row2>
                <Zip cta="Find plans" />
              </SavingsBody>
            </Savings>
          </Container>
        </Row2>
      </Block2>
      <Row2 size="large">
        <Boxes>
          <Box color="purple">
            <Container>
              <Guide>
                <GuideShield>
                  <StaticImage
                    src="./images/shield@2x.png"
                    alt=""
                    placeholder="tracedSVG"
                    width={336}
                  />
                </GuideShield>
                <Box color="white" size="small">
                  <Row2>
                    <Row2 size="xsmall">
                      <Text element="h2" size="xxxlarge">
                        Health Enrollment Guide
                      </Text>
                    </Row2>
                    <Text color="grey" element="p" flush>
                      Want assistance navigating health insurance? Catch is here to help every step
                      of the way.
                    </Text>
                  </Row2>
                  {GUIDE_LINKS.map((item) => (
                    <Row2 key={item.text} size="xxsmall">
                      <Button icon="chevron" variant="bar">
                        {item.text}
                      </Button>
                    </Row2>
                  ))}
                </Box>
              </Guide>
            </Container>
          </Box>
          <Box color="black">
            <Container narrow>
              <Row2 size="large">
                <Text element="h2" size="xxxxlarge">
                  What’s the catch?
                  <br />
                  <Text color="purple">There isn’t one.</Text>
                </Text>
              </Row2>
              <Row2 size="large">
                <div className="dark">
                  {FAQS.map((item) => (
                    <Accordion color="white" key={item.question} title={item.question}>
                      {item.answer}
                    </Accordion>
                  ))}
                </div>
              </Row2>
              <FaqFooter element="p" flush>
                Something else? See answers to more questions{' '}
                <Link to={paths.GUIDES_HEALTH} color="white">
                  here
                </Link>
                .
              </FaqFooter>
            </Container>
          </Box>
        </Boxes>
      </Row2>
      <Block2>
        <Container>
          <Row2 size="xlarge">
            <Box color="purple">
              <Text align="center" element="div">
                <Title subtitle="Plus, exclusive access to financial tools from Catch when you join Aetna CVS Health.">
                  Find the right plan for you
                </Title>
                <Row2>
                  <Zip cta="Get covered" helpColor="inherit" />
                </Row2>
                <AetnaSignUpText color="inherit" />
              </Text>
            </Box>
          </Row2>
          <Text color="grey" element="footer">
            {isTexas ? (
              <>
                <Text {...footerTextProps}>
                  *FOR PLANS STARTING AT $0: Not available in all states. Exclusions & limitations
                  apply.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $0 PCP VISITS: Select plans with $0 visits every time you see your primary
                  care provider. Members enrolled in qualified high-deductible health plans must
                  meet their deductible before receiving covered primary care or non-preventive
                  services at no cost-share.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $0 WALK-IN CLINIC VISITS: For a list of participating walk-in clinics, use
                  our online provider search tool. Includes select MinuteClinic services. Not all
                  MinuteClinic services are covered or may be covered at negotiated contract rates.
                  Not available in all states. Walk-in appointments aren’t guaranteed. Online
                  scheduling recommended. Check your plan documents for more detail. Members
                  enrolled in qualified high-deductible health plans must meet their deductible
                  before receiving covered primary care or non-preventive services at no cost-share.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $3 COPAY: This is not available in California and New Jersey. Pharmacy
                  benefits are administered by an affiliated pharmacy benefit manager, CVS
                  Caremark®.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $25 ALLOWANCE: $25 allowance each quarter to use on select CVS Health® brand
                  products. Unused allowance does not carry over to the next quarter. This benefit
                  is not available in California, Missouri or New Jersey.
                </Text>
                <Text {...footerTextProps}>
                  In Texas, discount programs are NOT insurance and program features are not
                  guaranteed under the plan contract and may be discontinued at any time.
                </Text>
                <Text {...footerTextProps}>
                  *FOR ADULT DENTAL AND VISION: Coverage for select plans. Available in all states
                  except for California, Maryland, Delaware, New Jersey and Utah.
                </Text>
                <Text {...footerTextProps}>
                  Health plans are offered or underwritten or administered by Aetna Health Inc.
                  (Texas) (Aetna). Aetna is part of the CVS Health family of companies.
                </Text>
              </>
            ) : (
              <>
                <Text {...footerTextProps}>
                  *FOR PLANS STARTING AT $0: Not available in all states. Exclusions & limitations
                  apply.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $0 PCP VISITS: Select plans with $0 visits every time you see your primary
                  care provider. Members enrolled in qualified high-deductible health plans must
                  meet their deductible before receiving covered primary care or non-preventive
                  services at no cost-share.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $0 MINUTE CLINIC VISITS: For a list of participating walk-in clinics, use our
                  online provider search tool. Includes select MinuteClinic services. Not all
                  MinuteClinic services are covered or may be covered at negotiated contract rates.
                  Not available in all states. Walk-in appointments aren’t guaranteed. Online
                  scheduling recommended. Check your plan documents for more detail. Members
                  enrolled in qualified high-deductible health plans must meet their deductible
                  before receiving covered primary care or non-preventive services at no cost-share.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $3 COPAY: This is not available in California and New Jersey. Pharmacy
                  benefits are administered by an affiliated pharmacy benefit manager, CVS
                  Caremark®.
                </Text>
                <Text {...footerTextProps}>
                  *FOR $25 ALLOWANCE: $25 allowance each quarter to use on select CVS Health® brand
                  products. Unused allowance does not carry over to the next quarter. This benefit
                  is not available in California, Missouri or New Jersey.
                </Text>
                <Text {...footerTextProps}>
                  *FOR ADULT DENTAL AND VISION: Coverage for select plans. Available in all states
                  except for California, Maryland, Delaware, New Jersey and Utah.
                </Text>
                <Text {...footerTextProps} flush>
                  Health plans are offered or underwritten or administered by Aetna Health of
                  California Inc., Aetna Health Inc. (Florida), Aetna Health Inc. (Georgia), Aetna
                  Life Insurance Company, Aetna Health of Utah Inc., Aetna Health Inc.
                  (Pennsylvania), or Aetna Health Inc. (Texas) (Aetna). Aetna is part of the CVS
                  Health® family of companies.
                </Text>
              </>
            )}
          </Text>
        </Container>
      </Block2>
    </Page>
  );
}
